<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';

export default {
  locales: {
    pt: {
      'My Cards': 'Minhas Cartelas',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'My Cards': 'Mi Tarjetas',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      cards: {
        list: null,
        loading: true,
        errored: false,
        empty: false,
      },
      modal: {
        status: false,
        number: null,
        balls: []
      }
    };
  },
  methods: {
    getCards() {
      this.cards.list = null

      this.cards.loading = true
      this.cards.errored = false
      this.cards.empty = false

      api
        .get('bingo/cards')
        .then(response => {
          if (response.data.status=='success') {
            this.cards.list = response.data.list
          }
        })
        .catch(error => {
          this.cards.errored = error
        })
        .finally(() => {
          this.cards.loading = false
          if (this.cards.list=='' || this.cards.list==null) {
            this.cards.empty = true
          }
        })
    },
    showCard: function (id) {
      api
        .get('bingo/cards/' + id)
        .then(response => {
          if (response.data.status=='success') {
            this.modal.number = response.data.number
            this.modal.balls = response.data.balls
            this.modal.status = true
          }
        })
    }
  },
  mounted() {
    this.getCards()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('My Cards') }}</h4>
        </div>
      </div>
    </div>

    <div v-if="cards.errored">
      <div class="card">
        <div class="card-body">
          {{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}
        </div>
      </div>
    </div>
    <div v-else-if="cards.empty">
      <div class="card">
        <div class="card-body">
          {{ t('Você não possui cartela ativa.') }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-6 col-xl-4" v-for="(row,index) in cards.list" :key="index">
          <div class="card">
            <div class="card-body text-center">
              <div class="d-flex text-center">
                <div class="flex-fill">
                  <small>Sorteio nº</small>
                  <h5 class="mb-3">{{ row.round.number }}</h5>
                </div>
                <div class="flex-fill">
                  <small>{{ row.round.date }}</small>
                  <h5 class="mb-3">{{ row.round.hour }}</h5>
                </div>
              </div>
              <table class="cardList table-bordered table-sm m-auto">
                <tr>
                  <td v-bind:class="[row.card.balls[0] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[0] }}</td>
                  <td v-bind:class="[row.card.balls[1] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[1] }}</td>
                  <td v-bind:class="[row.card.balls[2] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[2] }}</td>
                  <td v-bind:class="[row.card.balls[3] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[3] }}</td>
                  <td v-bind:class="[row.card.balls[4] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[4] }}</td>
                  <td v-bind:class="[row.card.balls[5] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[5] }}</td>
                  <td v-bind:class="[row.card.balls[6] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[6] }}</td>
                  <td v-bind:class="[row.card.balls[7] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[7] }}</td>
                  <td v-bind:class="[row.card.balls[8] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[8] }}</td>
                </tr>
                <tr>
                  <td v-bind:class="[row.card.balls[9] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[9] }}</td>
                  <td v-bind:class="[row.card.balls[10] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[10] }}</td>
                  <td v-bind:class="[row.card.balls[11] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[11] }}</td>
                  <td v-bind:class="[row.card.balls[12] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[12] }}</td>
                  <td v-bind:class="[row.card.balls[13] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[13] }}</td>
                  <td v-bind:class="[row.card.balls[14] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[14] }}</td>
                  <td v-bind:class="[row.card.balls[15] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[15] }}</td>
                  <td v-bind:class="[row.card.balls[16] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[16] }}</td>
                  <td v-bind:class="[row.card.balls[17] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[17] }}</td>
                </tr>
                <tr>
                  <td v-bind:class="[row.card.balls[18] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[18] }}</td>
                  <td v-bind:class="[row.card.balls[19] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[19] }}</td>
                  <td v-bind:class="[row.card.balls[20] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[20] }}</td>
                  <td v-bind:class="[row.card.balls[21] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[21] }}</td>
                  <td v-bind:class="[row.card.balls[22] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[22] }}</td>
                  <td v-bind:class="[row.card.balls[23] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[23] }}</td>
                  <td v-bind:class="[row.card.balls[24] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[24] }}</td>
                  <td v-bind:class="[row.card.balls[25] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[25] }}</td>
                  <td v-bind:class="[row.card.balls[26] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ row.card.balls[26] }}</td>
                </tr>
              </table>
              <div class="mt-3 d-flex text-center">
                <div class="flex-fill">
                  <small>Cartela</small>
                  <h5 class="mb-3">{{ row.card.number }}</h5>
                </div>
                <div class="flex-fill">
                  <small>Prêmios</small>
                  <h5 class="mb-3">{{ row.round.bingo }}</h5>
                </div>
              </div>
              <button @click="showCard(row.card.id)" class="btn btn-danger mr-2"><i class="bx bx-search-alt-2 font-size-18 align-middle"></i> AMPLIAR</button>
              <!-- <button @click="showCard(row.card.id)" class="btn btn-danger"><i class="bx bxs-download font-size-18 align-middle"></i> DOWNLOAD</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modal.status"
      body-class="p-0"
      centered
      hide-header
      hide-footer
    >
    <div class="p-3 text-center">
      <div class="d-flex text-center">
        <div class="flex-fill p-2">
          <img height="30px" src="@/assets/images/logo.png">
        </div>
        <div class="flex-fill">
          <small>Cartela</small>
          <h5 class="mb-3">{{ modal.number }}</h5>
        </div>
      </div>
      <table class="cardModal table-bordered w-fit50 m-auto">
        <tr>
          <td v-bind:class="[modal.balls[0] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[0] }}</td>
          <td v-bind:class="[modal.balls[1] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[1] }}</td>
          <td v-bind:class="[modal.balls[2] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[2] }}</td>
          <td v-bind:class="[modal.balls[3] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[3] }}</td>
          <td v-bind:class="[modal.balls[4] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[4] }}</td>
          <td v-bind:class="[modal.balls[5] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[5] }}</td>
          <td v-bind:class="[modal.balls[6] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[6] }}</td>
          <td v-bind:class="[modal.balls[7] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[7] }}</td>
          <td v-bind:class="[modal.balls[8] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[8] }}</td>
        </tr>
        <tr>
          <td v-bind:class="[modal.balls[9] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[9] }}</td>
          <td v-bind:class="[modal.balls[10] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[10] }}</td>
          <td v-bind:class="[modal.balls[11] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[11] }}</td>
          <td v-bind:class="[modal.balls[12] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[12] }}</td>
          <td v-bind:class="[modal.balls[13] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[13] }}</td>
          <td v-bind:class="[modal.balls[14] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[14] }}</td>
          <td v-bind:class="[modal.balls[15] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[15] }}</td>
          <td v-bind:class="[modal.balls[16] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[16] }}</td>
          <td v-bind:class="[modal.balls[17] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[17] }}</td>
        </tr>
        <tr>
          <td v-bind:class="[modal.balls[18] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[18] }}</td>
          <td v-bind:class="[modal.balls[19] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[19] }}</td>
          <td v-bind:class="[modal.balls[20] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[20] }}</td>
          <td v-bind:class="[modal.balls[21] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[21] }}</td>
          <td v-bind:class="[modal.balls[22] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[22] }}</td>
          <td v-bind:class="[modal.balls[23] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[23] }}</td>
          <td v-bind:class="[modal.balls[24] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[24] }}</td>
          <td v-bind:class="[modal.balls[25] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[25] }}</td>
          <td v-bind:class="[modal.balls[26] > 0 ? 'bg-danger text-white notranslate' : 'bg-soft-dark notranslate']">{{ modal.balls[26] }}</td>
        </tr>
      </table>
    </div>
    </b-modal>
  </Layout>
</template>

<style>
.cardList td {
  border: solid 2px #fff;
  width: 35px;
}
.cardModal {
  width: 100%;
}
.cardModal td {
  border: solid 2px #fff;
  width: 40px;
  height: 40px;
  font-size: 16px;
}
</style>